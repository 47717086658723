import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { displayPaymentStatus } from '../../../utils/utils'
import { useOutgoingInvoices } from '../../sales/outgoingInvoices/useOutgoingInvoices'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import i18n from '../../../libs/i18n'
import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import CardOutgoingInvoice from '@/components/card/OutgoingInvoice'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonPayment from '@/components/button/Payment'
import moment from 'moment'

export default {
  components: {
    SearchFilter,
    CardOutgoingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonPayment,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
      },
      {
        text: capitalize(i18n.t('myOffers')),
        key: '_filter_myOffers',
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'billNumber',
        sortable: true,
      },
      {
        display: capitalize(i18n.t('date')),
        key: 'billingDate',
        isDate: true,
        sortable: true,
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: '_dueDate',
        sortable: true,
        isDate: true,
      },
      {
        display: capitalize(i18n.t('paymentStatus')),
        key: '_paymentStatus',
        sortable: true
      },
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('remaining')),
        key: '_sumOwed',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const customerReceivable = computed(() => {
      return store.getters['outgoingInvoice/customerReceivable']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchOutgoingInvoices, fetchPaymentMethods, fetchBankAccounts } = useAPI()
    const {
      getOutgoingInvoiceIconStatus,
      getOutgoingInvoiceStatus,
      downloadOutgoingInvoice,
    } = useOutgoingInvoices()

    const getPreTaxAmounts = (outgoingInvoices) => {
      let preTaxAmount = 0
      outgoingInvoices.forEach(oi => preTaxAmount += parseFloat(oi.preTaxAmount))

      return preTaxAmount
    }

    const getTotalAmounts = (outgoingInvoices) => {
      let totalAmount = 0
      outgoingInvoices.forEach(oi => totalAmount += parseFloat(oi.totalAmount))

      return totalAmount
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchOutgoingInvoices()
    fetchPaymentMethods()
    fetchBankAccounts()

    return {
      // Components
      capitalize,
      currency,
      displayPaymentStatus,
      moment,

      // Data
      sortOptions,
      columns,

      // Computed
      customerReceivable,

      // Methods
      getOutgoingInvoiceIconStatus,
      getOutgoingInvoiceStatus,
      downloadOutgoingInvoice,
      getPreTaxAmounts,
      getTotalAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}